// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

global.$ = require("jquery")

import "../../../node_modules/selectize/dist/js/selectize.min.js"

Rails.start()
ActiveStorage.start()

import 'bootstrap';

$(document).ready(() => {
  $('[data-toggle="tooltip"]').tooltip()
});

require('admin-lte');

import '../stylesheets/application';
import "@fortawesome/fontawesome-free/js/all";

import "./vendor/jquery.doubleScroll"

import "./main"
