wait_for_element = function (selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve($(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve($(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

$(document).ready(function () {
  $(".double-scroll").doubleScroll();

  $("select.selectize:not(.selectized)").each(function (index, element) {
    let resource_type = $(element).data("resource-type");
    let resource_base_path = "/resources/" + resource_type;
    let options = {};

    if (resource_type) {
      options = {
        ...options,
        load: function (q, callback) {
          if (!q.length) return callback();

          let exclude_ids = Object.keys(this.options);

          $.ajax({
            url: resource_base_path,
            type: "GET",
            data: { q, exclude_ids },
            success: function (result) {
              callback(result);
            },
          });
        },
      };
    }

    if ($(element).data("create")) {
      options = {
        ...options,
        create: function (input, callback) {
          if (!resource_type) return { value: input, text: input };

          $.ajax({
            url: resource_base_path,
            type: "POST",
            data: { input },
            success: function (result) {
              callback(result);
            },
          });
        },
      };
    }
    if ($(element).data("simple-create") && !$(element).data("create")) {
      options = {
        ...options,
        create: true,
      };
    }

    $(element).selectize(options);
  });

  $(".show-hide .show-hide-toggle").click(function () {
    let show_hide = $(this).closest(".show-hide");
    let content = show_hide.find(".show-hide-content");

    content.toggle();
    if (content.is(":visible")) {
      $(this).text($(this).data("hide-text"));
    } else {
      $(this).text($(this).data("show-text"));
    }
  });

  $(".language-select").on("change", function (e) {
    const videoSelect = $(".video-select select.selectize")?.selectize()?.[0]
      ?.selectize;
    const attachmentSelect = $(
      ".attachment-select select.selectize"
    )?.selectize()?.[0]?.selectize;

    const surveySelect = $(".survey-select select.selectize")?.selectize()?.[0]
      ?.selectize;

    $.ajax({
      url: `/document_categories/${e?.target?.value}/change_options`,
      type: "GET",
      data: { language: e?.target?.value },
      success: function (data) {
        videoSelect.clear();
        videoSelect.clearOptions();
        if (data?.videos?.length > 0) {
          const filterVideos = data?.videos?.map((i) => {
            return {
              value: i?.id,
              text: i?.name,
            };
          });
          videoSelect.addOption(filterVideos);
        }
        attachmentSelect.clear();
        attachmentSelect.clearOptions();
        if (data?.attachments?.length > 0) {
          const filterAttachments = data?.attachments?.map((i) => {
            return {
              value: i?.id,
              text: i?.name,
            };
          });
          attachmentSelect.addOption(filterAttachments);
        }
        surveySelect.clear();
        surveySelect.clearOptions();
        if (data?.surveys?.length > 0) {
          const filterSurveys = data?.surveys?.map((i) => {
            return {
              value: i?.id,
              text: i?.name,
            };
          });
          surveySelect.addOption(filterSurveys);
        }
      },
    });
  });

  $(".language-selector").on("change", function (e) {
    const documentCategorySelect = $(
      ".document-category-select select.selectize"
    )?.selectize()?.[0]?.selectize;

    $.ajax({
      url: `/document_categories/${e?.target?.value}/change_document_categories_options`,
      type: "GET",
      data: { language: e?.target?.value },
      success: function (data) {
        documentCategorySelect.clear();
        documentCategorySelect.clearOptions();
        if (data?.document_categories?.length > 0) {
          const filterDocumentCategories = data?.document_categories?.map(
            (i) => {
              return {
                value: i?.id,
                text: i?.title,
              };
            }
          );
          documentCategorySelect.addOption(filterDocumentCategories);
        }
      },
    });
  });

  $(".image-uploader input[type=file]").change(function () {
    let file = $(this)[0].files[0];
    let preview = $(this)
      .closest(".image-uploader")
      .find(".image-uploader-preview");
    let src = preview.data("default");
    if (file) {
      src = URL.createObjectURL(file);
    }
    preview.attr("src", src);
  });
});

hidePreview = () => {
  $(".preview").hide();
};

setLanguage = (language) => {
  document.cookie = "language=" + $(language).val() + "; path=/";
  location.reload();
};
